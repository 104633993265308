<template>
  <div>
    <h1>welcome</h1>
  </div>
</template>
<script>
export default {
  mounted(){
    this.$router.push("/order/list");
  }
}
</script>